@use '../abstracts' as *;

h1,
h2,
h3 {
    font-family: $font-family-fancy;
    white-space: pre-line;
}

h1,
h2 {
    font-weight: $font-weight-normal;
    font-size: $font-size-h1;
}

h1 {
    color: $color-text-secondary;
    padding-bottom: 1rem;
}

h2 {
    margin-bottom: 3rem;
}

h3 {
    margin-bottom: 1rem;
    font-weight: $font-weight-normal;
    font-size: $font-size-h3;
}

h4 {
    margin-bottom: 0.5rem;
    font-size: $font-size-h4;
}

h5 {
    font-weight: $font-weight-xbold;
    font-size: $font-size-info-title;
}