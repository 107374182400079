@use '../abstracts/' as *;
.prices-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: 250px 1fr;
	gap: 5rem;
	align-items: start;

	@include media-md {
		display: flex;
		flex-direction: column;
		gap: 0;
	}

	.tabs-nav {
		margin-bottom: 3rem;
		padding-left: 0px;
		border: 1px solid rgba($color-text, 0.2);
		border-radius: 1rem;

		@include media-md {
			display: grid;
			grid-template-columns: repeat($tab-nav-columns-m, 1fr);
		}

		@include media-sd {
			grid-template-columns: repeat($tab-nav-columns-s, 1fr);
		}

		li {
			padding: 0.5rem;
			list-style: none;
			text-align: center;
			font-weight: 400;
			cursor: pointer;
			@include flex-center;
			@include transition-ease-fast;

			&:hover {
				background: rgba($color-accent, 0.2);
			}
	
			&.active {
				color: $color-text-secondary;
				background: $color-secondary;
			}

			@include media-md-min-max {
				@include set-corner-round($tab-nav-columns-m);
			}

			@include media-sd-max-or-md-min {
				@include set-corner-round($tab-nav-columns-s);
			}
		}
	}
}

.prices {
	max-width: 1200px;

	&-item {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0.5rem 0.5rem;
		padding-bottom: 0;
		@include transition-ease-fast;

		&:hover {
			border-radius: 5px;
			background-color: rgba($color-accent, 0.1);
		}

		p {
			width: auto;
			margin-bottom: 0.2rem;

			dt {
				margin-right: -8px;
			}
		}
	}

	&-multiple {
		flex-direction: column;

		&-items {
			display: grid;
			grid-template-columns: repeat(2, auto);
			padding-left: 2rem;

			p {
				padding-top: 0.2rem;
			}

			p:nth-child(2n) {
				text-align: right;
			}
		}
	}

	dd,
	aside {
		padding: 1rem 1rem 0rem;
	}
}
