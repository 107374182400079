@use '../abstracts' as *;

.important-info {
    margin: 2rem 1rem 3rem;
    padding: 1rem;
    border-radius: 1rem;
    background: $color-secondary;
    color: $color-background;
    
    p {
        width: auto;
        margin: 1rem;
        
        .text-link {
            color: white;
            text-decoration: underline;
        }
    }
    
    &-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin: 1rem;
    }

    @include media-sd {
        margin-inline: 0;

        &-title,
        p {
            margin-inline: 0;
            margin-block: 0.5rem;
        }
    }
}