@use '../abstracts/' as *;

.contact {
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 3rem;
	
	> div {
		width: 50%;
	}
	
	&-info {
		@include flex-column;
		
		h3 {
			margin-bottom: 2rem;
		}
		
		.contact-box {
			display: flex;
			align-items: center;
			gap: 1rem;

			.address-icon {
				text-align: center;
			}

			p, address {
				font-style: italic;
				margin-top: 1rem;
			}

			a {
				display: inline-block;
				margin-top: 1rem;
				
				&:last-child {
					margin-bottom: 1rem;
				}
			}
		}
		
		.button {
			align-self: center;
			margin-top: auto;
		}
	}

	@include media-sd {
		flex-direction: column;
		gap: 3rem;

		>div {
			width: 100%;
		}
	}
}

.map-container {
	align-items: center;
	
	.map {
		width: 100%;
		height: 50vh;
		margin-top: 2rem;
		border: none;
	}
}

.contact-form-info {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}