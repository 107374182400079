@use '../abstracts' as *;

header {
	position: absolute;
    top: 0;
    width: 100%;
	@include media-md {
		position: absolute;
		background-color: rgba($color-text, 0.5);
	}
	z-index: 10;

	.header-container {
		display: flex;
		justify-content: space-between;
		padding: 1rem 3rem;
		background-color: rgba($color-text, 0);
		@include transition-ease-fast;

		.logo img {
			min-width: 300px;
			@include transition-ease-fast;
			@include media-md {
				min-width: 250px;
			}
			@include media-sd {
				min-width: 150px;
			}
		}
	}
	
	.scrolled {
		background-color: rgba($color-text, 0.7);
		padding-block: 0.5rem;
	
		.logo img {
			width: 50%;
		}
	}

	.menu {
		@include flex-center;
	}
}