$banner-height: 40vh;
$banner-height-mobile: 30vh;
$article-min-height: calc(100vh - $banner-height - 73px);

$tab-child-no: 10;
$tab-nav-columns-l: 5;
$tab-nav-columns-m: 2;
$tab-nav-columns-s: 1;

$image-text-max-width: 350px;
$image-modal-max-width: 300px;
$image-history-max-width: 150px;

$gallery-columns-l: repeat(6, 1fr);
$gallery-columns-m: repeat(3, 1fr);
$gallery-columns-s: repeat(2, 1fr);
$gallery-gap-l: 5rem;
$gallery-gap-m: 3rem;
$gallery-gap-s: 2rem;
$gallery-overlay-nav-size: 50px;
$gallery-overlay-icon-size: 1.5rem;

$compare-line-width: 6px;