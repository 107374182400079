@use '../abstracts/' as *;

footer {
	display: flex;
	justify-content: center;
	background-color: $color-background-dark;
	color: $color-text-secondary;
	padding: 1.5rem;
	text-align: center;

	p {
		margin-inline: 1rem;
		margin-bottom: 0;
		width: auto;
		
		a.text-link {
			color: $color-text-secondary;
		}
	}
}

@include media-sd {
	footer {
		flex-direction: column;
		padding: 0.5rem;

		p {
			font-size: 12px;
		}
	}
}