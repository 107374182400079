@use '../abstracts' as *;

.compare{
    @include flex-column;
    gap: 1rem;
    margin-inline: 3rem;

    &-content {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 450px;
        cursor: col-resize;
        box-shadow: $img-shadow;

        img {
            width: 100%;
        }

        &-divider {
            position: absolute;
            top: 0;
            right: -10;
            height: 100%;
            display: flex;
            align-items: center;

            &-arrow {
                color: $color-accent;
            }
            
            &-line {
                height: 100%;
                width: $compare-line-width;
                background-color: $color-accent;
            }
        }

        &-active-img {
            position: absolute;
            height: 100%;
            object-fit: cover;
            object-position: 0%;
        }

        .img{
            width: 100%;
            max-width: 450px;
        }
    }

    &-captions {
        display: flex;
        justify-content: space-around;
    }
}