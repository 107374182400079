@use '../abstracts' as *;

// PAGES
#main-page {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_main.jpeg');
	background-position-x: 25%;
}

#appointments {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_appointment.jpeg');
	background-position-x: 100%;
	background-position-y: 35%;
}

#privacy-policy,
#about {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_about.jpeg');
	background-position-x: 50%;
	background-position-y: 40%;
}

#offer {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_offer.jpeg');
	background-position-x: 70%;
	background-position-y: 70%;
}

#prices {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_prices.jpeg');
	background-position-y: 25%;
}

#certificates {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_certificates.jpeg');
	background-position-y: 35%;
}

#not-found,
#faq {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_faq.jpeg');
	background-position-x: 100%;
	background-position-y: 30%;
}

#contact {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_contact.jpeg');
	background-position-x: 100%;
	background-position-y: 10%;
}

// OFFERS
#aesthetic-dentistry {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_aesthetics.jpeg');
	background-position-x: 80%;
	background-position-y: 20%;
}

#conservative-dentistry {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_conservatives.jpeg');
	background-position-y: 15%;
	background-position-x: 50%;
}

#dental-surgery {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_surgeries.jpeg');
	background-position-x: 100%;
	background-position-y: 50%;
}

#diagnostics {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_diagnostics.jpeg');
	background-position-x: 60%;
	background-position-y: 50%;
}

#digital-dentistry {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_digital.jpeg');
	background-position-x: 100%;
	background-position-y: 35%;
}

#endodontics {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_endodontics.jpeg');
	background-position-x: 100%;
	background-position-y: 10%;
}

#implantology {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_implants.jpeg');
	background-position-x: 100%;
}

#inlay-onlay {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_inlay_onlay.jpeg');
	background-position-x: 60%;
	background-position-y: 40%;
}

#joints {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_joints.jpeg');
	background-position-x: 70%;
	background-position-y: 60%;
}

#periodontics {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_periodontics.jpeg');
	background-position-x: 100%;
}

#prevention {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_prevention.jpeg');
	background-position-x: 95%;
	background-position-y: 50%;
}

#prosthodontics {
	background: set-page-background('https://bujnickadentstorage.blob.core.windows.net/bunicka-dent-images/banners/banner_prosthodontics.jpeg') right;
	background-position-y: 57%;
}

.page-wrapper {
	padding-top: $banner-height;

	.banner {
		@include fixed-full-width;
		height: $banner-height;
		background-size: cover !important;
		@include flex-column-center-screen;
		text-align: center;
		@include media-md {
			padding: 3rem;
		}

		&-title {
			border-bottom: 2px solid $color-text-secondary;
		}
	}

	@include media-sd{
		padding-top: $banner-height-mobile;
		.banner {
			height: $banner-height-mobile;

			h1 {
					font-size: $font-size-h3;
			}
		}
	}
}