@use '../abstracts/' as *;

.main-wrapper {
	padding-top: 100vh;

	.banner {
		height: 100vh;

		&-title {
			margin-block: 10vh;
		}

		.scroll-down-button {
			position: fixed;
			bottom: 1rem;
			font-size: 2vw;
			color: $color-background;
			cursor: pointer;
			
			* {
				@include transition-ease-fast;
			}

			@include media-md {
				font-size: 1.5rem;
			}
			
			&:hover {
				* {
					color: $color-accent;
					font-size: 2.5vw;
					@include transition-ease-fast;
					
					@include media-md {
						font-size: 2rem;
					}
				}
			}
		}

		.quote {
			position: absolute;
			bottom: 3rem;
			right: 3rem;
			color: $color-background;
			text-align: right;
			font-family: $font-family-fancy;

			q {
				font-size: 25px;
				font-style: italic;
			}

			.author {
				margin-top: 0.5rem;
				margin-bottom: 0;
			}
		}
	}

	article {
		min-height: 100vh;

		section {
			align-items: center;

			.main-page-container {
				display: grid;
				gap: 0 3rem;
				min-height: 50vh;
				max-width: 1100px;

				> * {
					width: 100%;
				}

				.main-page-title {
					align-self: end;
				}

				.main-page-img {
					@include flex-center-screen;
					grid-row-start: 1;
					grid-row-end: span 2;
					img {
						width: 100%;
						max-width: 450px;
					}
				}

				.main-page-text {
					@include flex-column;
					grid-column-start: 2;

					.button {
						margin-top: 2rem;
						align-self: center;
					}
				}
			}
			&:nth-child(even) {
				.main-page-container {
					.main-page-img {
						grid-column-start: 2;
						grid-row-start: 1;
						grid-row-end: span 2;
					}

					.main-page-text {
						grid-column-start: 1;
					}
				}
			}
		}
	}
}

@include media-md {
	.main-wrapper {
		.banner {
			&-title {
				margin-top: 0;

				h1 {
					font-size: 2rem;
				}
			}

			.quote {
				right: 1rem;
				p {
					font-size: 10px;
					margin-bottom: 0;
				}

				q {
					font-size: 18px;
				}

				.author {
					margin-top: 0px;
				}
			}
		}

		article section:nth-child(n) .main-page-container {
			width: 80%;
			.main-page-title {
				text-align: center;
			}

			.main-page-img {
				grid-column-start: 1;
				grid-row-start: 2;
				margin-bottom: 2rem;

				img {
					max-width: 350px;
				}
			}

			.main-page-text {
				grid-column-start: 1;
			}
		}
		
	}
}


@include media-sd {
	.main-wrapper article section:nth-child(n) .main-page-container {
		width: 100%;
	}
}