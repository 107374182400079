@use "sass:color";

$opacity-background: 0.9;

$color-background: #fff;
$color-text: #000;
$color-text-secondary: #fff;
$color-text-secondary-disabled: #c8c8c8;
$color-background-dark: #1f1f1f;
$color-background-trans: rgba($color-background-dark, $opacity-background);
$color-background-trans-hover: rgba($color-background, 0.1);
$color-secondary: #1b3c7b;
$color-accent: #3fbbd1;
$color-accent-light: color.scale($color-accent, $lightness: 30%);
$color-accent-dark: color.scale($color-accent, $lightness: -30%);
$color-success: green;
$color-error: red;

$img-shadow: 20px 20px 20px 2px rgba($color-background-dark, 0.4);
$img-shadow-far: 30px 30px 30px 5px rgba($color-background-dark, 0.4);