@use '../abstracts/' as *;

.gallery {
	display: grid;
	grid-template-columns: $gallery-columns-l;
	gap: $gallery-gap-l;
	@include media-td {
		grid-template-columns: $gallery-columns-m;
	}
	@include media-md {
		gap: $gallery-gap-m;
	}
	@include media-sd {
		grid-template-columns: $gallery-columns-s;
		gap: $gallery-gap-s;
	}

	img {
		width: 100%;
		box-shadow: $img-shadow;
		cursor: pointer;
		@include transition-ease-fast;

		&:hover {
			transform: scale(1.1);
			box-shadow: $img-shadow-far;
		}
	}
}
