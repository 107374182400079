@use './colors' as *;

$time-slow: 0.6s;
$time-fast: 0.2s;

@mixin transition-ease-fast {
	transition: all $time-fast ease-in-out;
}

@mixin transition-ease-slow {
	transition: all $time-slow ease-in-out;
}

@mixin transition-modal-closing {
	animation: outAnimation $time-fast ease-out;
}

@mixin transition-modal-opening {
	animation: inAnimation $time-fast ease-in;
}

@mixin transition-collapsible {
	transition: height $time-slow;
}

@mixin animation-pulse {
	animation: pulse $time-slow;
}

@keyframes pulse {
	100% {
		box-shadow: 0 0 0 0 $color-secondary;
	}
}

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}

	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@function set-item-delay($itemNo) {
	@return ($itemNo * 0.1s)+0.15s;
}
