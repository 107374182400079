@use '../abstracts' as *;

a {
    text-decoration: none;
    
    
    &:visited{
        color: $color-text-secondary;
    }
}

b {
    font-weight: $font-weight-bold;
}

dfn {
    font-weight: $font-weight-bold;
    font-style: normal;
}

abbr {
    text-decoration: none;
    font-style: italic;
    cursor: help;
}

p,
textarea,
address,
ul,
.contact-box p {
    margin-bottom: 1rem;
}

p,
input,
textarea,
address,
ul,
.contact-box p {
    width: 100%;
    font-weight: $font-weight-pale;
    font-size: $font-size-text;
    letter-spacing: 0.3px;
    line-height: 25px;
    @include media-md {
        font-size: $font-size-text-small;
        letter-spacing: initial;
    }

    &.center {
        text-align: center;
    }
}

textarea {
    resize: none;
}

ul {
    font-size: $font-size-ul;
    padding-left: 3rem;
    margin-bottom: 2rem;

    ul {
        margin-bottom: 0;
    }
}

.nowrap {
    white-space: nowrap;
}

.white {
    color: $color-text-secondary;
}

.error-message {
    color: $color-error;
}

.success-message {
    color: $color-success;
}

.author {
    color: $color-text-secondary;
}