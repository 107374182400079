@use '../abstracts' as *;

.text-img {
	display: flex;
	margin-bottom: 1rem;

	.photos {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		gap: 1rem;
	}
}

.page-photo {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	margin-inline: 2rem;

	img {
		max-width: $image-text-max-width;
		box-shadow: $img-shadow;
	}

	@include media-md {
		margin-inline: 0;
		img {
			width: 100%;
		}
	}
}

.horizontal {
	width: 100%;
	margin-bottom: 3rem;

	.photos {
		width: 100%;
		justify-content: space-around;

		.page-photo {
			margin-inline: 0;

			img {
				width: 100%;
			}
		}
	}
}

.normal {
	flex-direction: row-reverse;
	justify-content: space-between;
	gap: 5rem;
	margin-top: 1rem;

	@include media-md {
		flex-direction: column-reverse;
		gap: 2rem;
		&.reverse {
			flex-direction: column;
		}
		ul {
			margin-bottom: 0;
		}
	}
}

.upright {
	display: initial;

	.photos {
		float: right;
		flex-direction: column;
		margin-bottom: 1rem;
	}

	@include media-md {
		display: flex;
		flex-direction: column-reverse;
		.photos {
			flex-direction: row;
		}
	}
}

@include media-md {
	.downright {
		> div {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

@include media-md-min {
	.downright {
		.photos {
			float: right;
			height: 100%;
			shape-outside: inset(calc(100% - 300px) 0 0);

			.page-photo {
				align-self: flex-end;
			}
		}
	}
}

.loading {
	margin: auto;
	@include flex-column-center;
	gap: 1rem;
	text-align: center;
}

.img-loading {
	filter: blur(3px);
}

.img-loaded {
	filter: blur(0px);
}
