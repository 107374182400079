@use '../abstracts/' as *;

ol {
	margin: 1rem auto;
	max-width: 700px;
	list-style: none;

	> li {
		border-top: 2px dashed;
		border-color: $color-accent !important;
		margin: 0;
		padding: 30px;
		counter-increment: section;
		position: relative;

		&:first-child {
			border-top: 0;
			border-top-left-radius: 0 !important;
			&:after {
				display: none;
			}
		}

		&:nth-child(odd),
		&:nth-child(even) {
			&:before {
				box-sizing: unset;
				content: counter(section);
				position: absolute;
				border-radius: 50%;
				margin: -10px -16px 0;
				padding: 10px;
				height: 15px;
				width: 15px;
				background-color: $color-secondary;
				text-align: center;
				line-height: 0.9;
				color: $color-text-secondary;
				font-size: 110%;
			}

			&:after {
				box-sizing: unset;
				content: "";
				position: absolute;
				height: 30px;
				width: 28px;
				border-bottom: 2px dashed $color-accent;
			}
		}

		&:nth-child(odd) {
			border-left: 2px dashed;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
			margin-right: 30px;
			padding-right: 0;

			&:before {
				right: 100%;
			}

			&:after {
				top: -32px;
				left: 100%;
				border-bottom-right-radius: 30px;
			}
		}

		&:nth-child(even) {
			border-right: 2px dashed;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
			margin-left: 30px;
			padding-left: 0;

			&:before {
				left: 100%;
			}

			&:after {
				top: -33px;
				left: -29px;
				border-bottom-left-radius: 30px;
			}
		}

		&:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	&-item:first-child:after {
		border: none
	}
}

ol.wide {
	max-width: 95%;

	h3 {
		margin-top: -8px;
	}
}


