@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&amp;family=Playfair+Display:ital@0;1&amp;display=swap');

$font-family-classic: 'Montserrat', sans-serif;
$font-family-fancy: 'Playfair Display', serif;

$font-size-button: 0.8rem;
$font-size-text: 18px;
$font-size-text-small: 15px;
$font-size-ul: 16px;
$font-size-nav-l: 1.5rem;
$font-size-nav-m: 1.2rem;
$font-size-nav-scrolled: 1rem;
$font-size-nav-mobile: 2rem;
$font-size-h1: 2.2rem;
$font-size-h2: 2.2rem;
$font-size-h3: 1.8rem;
$font-size-h4: 1.2rem;
$font-size-info-title: 1.1rem;

$font-weight-xbold: 600;
$font-weight-bold: 500;
$font-weight-normal: 400;
$font-weight-pale: 300;