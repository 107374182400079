@use '../abstracts/' as *;

.appointment {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 5rem;
	max-width: 1400px;
	
	.calendar {
		text-align: center;
		grid-column-start: 2;
		grid-column-end: 2;
		grid-row-start: 1;
		grid-row-end: 10;

		&-wrapper {
			.info {
				display: block;
				margin: 1rem;
			}
		}

		&-nav {
			background-color: $color-secondary;
			display: flex;
			align-items: center;
			padding-inline: 1rem;

			p {
				margin: auto;
				color: $color-text-secondary;
				padding: 1rem;
			}

			&-button {			
				svg {
					margin-top: 5px;
					cursor: pointer;
					color: $color-text-secondary;
					font-size: 1.5rem;
					width: 1.5rem;
					@include transition-ease-fast;
				}

				&:not(.disabled) {
					&:hover * {
						font-size: 1.7rem;
					}
				}
				
				&.disabled {
					svg {
						cursor: not-allowed;
						color: $color-text-secondary-disabled;
					}
				}
			}
		}

		&-schedule {
			position: relative;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			grid-template-rows: auto 1fr;
			grid-auto-flow: column;
			flex-direction: row;
			justify-content: space-around;
			width: 100%;

			&-loader {
				background-color: #fffb;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&-day {
				display: flex;
				align-items: center;
				margin: 0.5rem;
				gap: 0.5rem;

				&.feast{
					background-color: red;
					margin: 0.5rem;
					border-radius: 10px;
					color: white;
				}

				p {
					font-size: 0.9rem;
					margin-bottom: 0;
				}
			}

			&-times {
				display: flex;
				flex-basis: 100%;
				flex-direction: column;
				align-items: center;
				padding: 0.5rem;
				gap: 0.5rem;

				p {
					font-size: 1rem;
					margin-bottom: 0;
				}
			}

			&-time {
				border: 1px solid rgba($color-text, 0.2);
				background-color: $color-background;
				border-radius: 1rem;
				padding-block: 5px;
				max-width: 70px;
				width: 100%;
				min-width: 45px;
				cursor: pointer;
				
				&:disabled,
				&[disabled] {
					background-color: $color-text-secondary-disabled;
					pointer-events: none;
				}
				
				&:hover {
					@include transition-ease-fast;
					background-color: $color-accent-light;
					color: $color-text-secondary;
				}
			}

			.time-selected {
				background-color: $color-secondary;
				color: $color-background;
				cursor: default;
			}
		}
	}

	.error-calendar .calendar-wrapper {
		border-bottom: 2px solid $color-error;
	}
}

@media screen and (max-width: 610px) {
	.appointment {
		display: initial;

		.calendar {
			margin-top: 1rem;
		}
	}
}