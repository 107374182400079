@use '../abstracts' as *;

.collapsible {
    padding: 2rem 3rem;
    margin-bottom: 0;
    @include media-md {
        padding-inline: 0;
    }

    
    &-header {
        display: flex;
        align-items: center;
        gap: 2rem;
        cursor: pointer;
        
        &:hover {
            .marker {
                background: rgba($color-accent, 0.2);
            }
        }

        > h3 {
            margin: 0;
        }

        .marker {
            min-height: 2.5rem;
            min-width: 2.5rem;
            border: 1px solid rgba($color-text, 0.5);
            border-radius: 3rem;
            @include flex-center;
            @include transition-ease-fast;

            &.opened {
                transform: rotate(-540deg);
            }
        }

        @include media-sd {
            gap: 1rem;
            >h3 {
                font-size: $font-size-h4;
            }

            i {
                min-height: 2.0rem;
                min-width: 2.0rem;
                font-size: 1rem;
            }
        }
    }

    &-body{
        height: 0;
        overflow: hidden;
        @include transition-collapsible;

        &-content {
            border-top: 1px solid rgba($color-text, 0.2);
            margin-left: 4.5rem;
            margin-top: 1rem;
            padding-top: 1rem;
            @include media-md {
                margin-left: 0;
            }

            .important-info {
                margin-bottom: 0;
            }
        }
    }
}