@use '../abstracts/' as *;

.history {
	align-items: center;
	padding-inline: 10rem;
	padding-bottom: 0;

	&-text {
		margin-bottom: 3rem;
	}

	.horizontal .photos {
		gap: 3rem;
		justify-content: center;

		.page-photo {
			gap: 2rem;
			&-text {
				@include flex-column-center;
				span {
					font-weight: $font-weight-pale;
				}
			}
		}
	}	
}

.employee {
	display: grid;
	justify-items: center;
	gap: 2rem 5rem;

	&-title {
		text-align: center;
		h2 {
			margin-bottom: 0.5rem;
		}

		.subheadder {
			color: $color-accent;
			text-transform: uppercase;
		}
	}

	&-photo {
		max-width: 260px;
	}

	&-about {
		width: 100%;
	}
}

@include media-md {
	.history {
		padding-inline: 0;
	}
}

@include media-md-min {
	.employee {
		justify-items: flex-start;
		&-title {
			text-align: left;
		}

		&-photo {
			grid-row-start: 1;
			grid-row-end: span 2;
		}
			
		&-about {
			grid-column-start: 2;
		}
	}
}