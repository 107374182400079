@use '../abstracts' as *;

.h-desc {
	padding: 0 3rem 5rem;
	text-align: center;
}

.offers {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3vw;

	&-single {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 100%;
		}

		h3 {
			text-align: center;
			margin-top: 0.5rem;
		}

		.button {
			margin-top: auto;
		}
	}
}

@include media-md {
	.offers {
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
	}
}

@include media-sd {
	.offers {
		grid-template-columns: repeat(1, 1fr);
	}
}