@use './abstracts' as *;
@use './base';
@use './components';
@use './layout';
@use './pages';

body {
	font-family: $font-family-classic;
}

i {
	@include transition-ease-fast;
}

article.loading {
	min-height: calc(100vh - 73px);
	background-color: $color-text-secondary-disabled;
	display: flex;
	justify-content: center;
}