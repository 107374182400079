@use '../abstracts' as *;

.text-link,
.button {
    cursor: pointer;
    text-decoration: none;
}

.text-link {
    color: $color-accent;

    a {
        color: $color-accent;
        text-decoration: none;
    }

    &:hover {
        text-decoration: underline;
    }
}
.button {
    padding: 1rem 2rem;
    border-radius: 10rem;
    font-size: $font-size-button;
    color: $color-text-secondary;
    background-color: $color-secondary;
    @include transition-ease-fast;
    
    &:hover {
        background-color: $color-accent;
    }
}

.submit {
    border: none;
    width: auto;
    font-weight: 400;
    line-height: inherit;
}

#main-page .button {
    border-top: 1px solid $color-background;
    border-bottom: 1px solid $color-background;
    background-color: transparent;
    @include transition-ease-slow;

    &:hover {
        background-color: $color-secondary;
        border-color: rgba($color-background, 0.3);
        @include animation-pulse;
        box-shadow: 0 0 0 2rem rgba(red, 0);
    }
}