.privacy-policy {

	section {
		flex-direction: column;
	}

	p,
	li {
		max-width: 800px;
	}
}